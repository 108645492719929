import { Routes, Route, useLocation } from 'react-router-dom';
import About from './pages/About';
import Writing from './pages/Writing';
import Contact from './pages/Contact';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Draggable } from 'gsap/Draggable';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import TheYoungSamaritan from './pages/TheYoungSamaritan';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useGSAP } from '@gsap/react';
import PageNotFound from './components/404';
import TYSReviews from './pages/TYSReviews';

gsap.registerPlugin(
  ScrollTrigger,
  ScrollToPlugin,
  Draggable,
  ScrollSmoother,
  InertiaPlugin
);

function App() {
  useGSAP(() => {
    const smoother = ScrollSmoother.create({
      wrapper: '#smooth-wrapper',
      content: '#smooth-content',
      smooth: 1.3,
      effects: false,
    });
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      '#sky',
      { y: 0 },
      {
        y: 800,
        scrollTrigger: {
          trigger: '#sky',
          scrub: true,
          start: 'top top',
        },
      }
    );
    gsap.fromTo(
      '#m',
      { y: 0 },
      {
        y: 600,
        scrollTrigger: {
          trigger: '#m',
          scrub: true,
          start: 'top top',
        },
      }
    );
    gsap.fromTo(
      '#text',
      { y: 0 },
      {
        y: 200,
        scrollTrigger: {
          trigger: '#text',
          scrub: true,
          start: 'top top',
        },
      }
    );
    gsap.fromTo(
      '#boy',
      { y: 0 },
      {
        y: 500,
        scrollTrigger: {
          trigger: '#boy',
          scrub: true,
          start: 'top top',
        },
      }
    );
    gsap.fromTo(
      '#rock',
      { y: 0 },
      {
        y: 250,
        scrollTrigger: {
          trigger: '#rock',
          scrub: true,
          start: 'top top',
        },
      }
    );
  }, []);

  useGSAP(() => {
    gsap.utils.toArray('.animate-text').forEach((element) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: element,
          toggleActions: 'play none none reset',
          once: false,
        },
        y: 0,
        opacity: 0,
        delay: 0.2,
      });
    });
  });

  const location = useLocation();

  return (
    <div className='App' id='top'>
      <Navbar />
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <Routes location={location} key={location.pathname}>
            <Route index element={<TheYoungSamaritan />} />
            <Route path='/home' element={<TheYoungSamaritan />} />
            <Route path='/about' element={<About />} />
            <Route path='/writing' element={<Writing />} />
            <Route path='/contact' element={<Contact />} />
            <Route
              path='/writing/the-young-samaritan'
              element={<TheYoungSamaritan />}
            />
            <Route
              path='/writing/the-young-samaritan/reviews'
              element={<TYSReviews />}
            />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <Footer />
          <div className='bottom-padding'></div>
        </div>
      </div>
    </div>
  );
}

export default App;
